// src/common/configuratore/ModelsScene.tsx
import React from "react";
import GLTFModel from "./GLTFModel";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

interface ModelsSceneProps {
  setVisibility: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  handleModelLoaded: (gltf: GLTF) => void;
  visibility: { [key: string]: boolean };
  modelPath: string | null;
}

const ModelsScene: React.FC<ModelsSceneProps> = ({
  setVisibility,
  handleModelLoaded,
  visibility,
  modelPath,
}) => {
  return modelPath ? (
    <GLTFModel
      modelPath={modelPath}
      position={[0, 0, 0]}
      scale={[10, 10, 10]}
      setVisibility={setVisibility}
      onLoad={handleModelLoaded}
      visibility={visibility}
    />
  ) : null;
};

export default ModelsScene;
