import React from "react";

interface ProductDescriptionProps {
    title: string;
    description?: string;
}

class ProductDescription extends React.Component<ProductDescriptionProps> {
    render() {
        const { title, description } = this.props;

        return (
            <div className=" grid md:grid-cols-2 sm:grid-row gap-4 text-white w-full py-8 px-10  xl:px-32 ">
                <div className="col-span-1 flex flex-col  justify-center ">
                    <div className="text-4xl md:text-2xl lg:text-4xl font-bold text-left uppercase ">{title}</div>
                </div>
                <div className="uppercase flex items-center  text-left w-full h-auto">
                    <p>{description}</p>
                </div>
            </div>
        );
    }
}

export default ProductDescription;
