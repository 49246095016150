import React from "react";

interface NewsProps {
    descriptionBefore?: string;
    titleBefore?: string;
    descriptionAfter?: string;
    titleAfter?: string;
    title?: string;
    imgSrc?: string;
}

class SliderItemAbout extends React.Component<NewsProps> {
    render() {
        const {descriptionBefore, titleBefore, descriptionAfter,titleAfter, title, imgSrc} = this.props;

    return (
      <div className="relative h-full md:h-screen flex items-center justify-center pb-10">
        <img
          className="absolute inset-0 object-cover w-full h-full opacity-50 z-10 ml-0 md:ml-3"
          alt=""
          src="/render_erica/REVOLVE SPACE_WEB BACKGROUND_03_CAREER 1.jpg"
        />
        <div className="z-30 h-4/6 w-4/6 md:h-1/2 md:w-1/2 flex flex-col items-center justify-center text-white uppercase space-y-4">
          <img
            className="w-auto h-auto  pb-8"
            src={imgSrc}
            alt=""
          />
           <h1 className=" text-3xl text-center font-bold uppercase">
                        {title}
                    </h1>
                    <div className="grid grid-cols-2 gap-6 md:gap-4 text-xs md:text-lg  text-left justify-end ">
                        <div className="flex flex-col">
                            <h1 className="font-bold">{titleBefore}</h1>
                            <p>{descriptionBefore}</p>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="font-bold">{titleAfter}</h1>
                            <p>{descriptionAfter}</p>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default SliderItemAbout;