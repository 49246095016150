import React from "react";

interface NewsProps {
  imageSrc: string;
  description?: string;
}

class News extends React.Component<NewsProps> {
  render() {
    const { imageSrc, description } = this.props;

    return (
      <div className="grid md:grid-cols-2 sm:grid-row gap-4 text-white w-full py-8">
        <div className="col-span-1 flex flex-col items-center justify-center">
          <img className="my-2" src={imageSrc} alt="1" />
        </div>
        <div className="uppercase flex items-center md:text-left text-center  text-xl xl:text-2xl ">
          <p>{description}</p>
        </div>
      </div>
    );
  }
}

export default News;
