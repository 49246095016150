import React from 'react';

interface ImageTextProps {
    text?: string;
    imageSrc: string;
    description?: string;
    textAlign?: 'left' | 'center' | 'right';
}

class ImageTextHome extends React.Component<ImageTextProps> {
    render() {
        const { text, imageSrc, description, textAlign = 'center' } = this.props;

        return (
            <div className="relative flex flex-col justify-start  space-y-0 h-full  ">
                <div className=" ">
                    <img src={imageSrc} alt={text} className=" object-center " />
                    <p style={{textAlign: textAlign}} className="relative bottom-0 text-left text-white uppercase  text-3xl font-montserrat font-bold">{text}</p>
                    {description && <p style={{textAlign: textAlign}} className=" uppercase text-white lg:w-2/4 h-auto text-xl font-montserrat font-normal">{description}</p>}
                </div>
            </div>
        );
    }
}

export default ImageTextHome;