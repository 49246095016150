import Slider from 'react-slick';
import "./ImageSlider.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
    dots: false,
    arrows: true, 
    infinite: true,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
};

const ProductSlider = () => (
    <Slider {...settings} className="flex">
        {/*<img src="/products/product-sara-0.png" alt="" className=" h-[500px]  object-contain " />
        <img src="/products/product-sara-1.png" alt="" className=" h-[500px] object-contain " />
        <img src="/products/product-sara-2.png" alt="" className=" h-[500px] object-contain " />*/}
        <img src="/products/product-sara-3.png" alt="" className=" h-[500px] object-contain " />
        <img src="/products/product-sara-4.png" alt="" className="h-[500px] object-contain " />
        <img src="/products/product-sara-5.png" alt="" className=" h-[500px] object-contain " />
        <img src="/products/product-sara-6.png" alt="" className=" h-[500px] object-contain " />
    </Slider>
);





export default ProductSlider;