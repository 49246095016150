import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";

interface SimpleSliderProps {
  children: React.ReactNode;
}

class SimpleSlider extends React.Component<SimpleSliderProps> {
  
  render() {
      const settings = {
        dots: true,
        speed: 500,
        infinite: false,
        slidesToShow: 1, // Mostra due slide.
        slidesToScroll: 1, // Scorri un slide alla volta
        centerMode: true, // Abilita il centratura dell'elemento selezionato
        centerPadding: '0px', // Definisce la quantità di spazio tra il bordo del contenitore e l'elemento selezionato
        arrows: true,
      };
    return (
      <div className="image-slider-container">
        <Slider {...settings}>

          {this.props.children}
        </Slider>
      </div>
    );
}
}
export default SimpleSlider;

