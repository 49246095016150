import Slider from 'react-slick';
import "./ImageSlider.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const careersettings = {
    dots: false,
    arrows: true, 
    infinite: true,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
};

const CareerSlider = () => (
    <Slider {...careersettings} className="flex">
        <img src="/careers_slider/career-slider-0.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-1.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-2.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-3.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-4.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-5.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-6.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-7.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/careers_slider/career-slider-8.jpg" alt="" className=" h-[500px] object-contain " />


    </Slider>
);

export default CareerSlider;