import React, { useEffect } from 'react';

interface ProductWebFormProps {
    formUrl: string;
}

const ProductWebForm: React.FC<ProductWebFormProps> = ({ formUrl }) => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;

        // Append script to the div with the class "pipedriveWebForms"
        const formDiv = document.querySelector('.pipedriveWebForms');
        formDiv?.appendChild(script);
    }, [formUrl]);

    return (
        <div className="pipedriveWebForms" data-pd-webforms={formUrl}>
            {/* The form will be loaded here by the script */}
        </div>
    );
};

export default ProductWebForm;
