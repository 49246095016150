import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";

export default function Product_SARA() {

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fileType, setFileType] = useState<string | null>(null);

  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <div>
        {/* GIF sopra l'immagine di sfondo */}
        <img
          src="/SaraGif.gif"
          alt="Sara Gif"
          className="absolute inset-0 w-full h-full object-cover opacity-75 bg-transparent "
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
      </div>
      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 ">
          {/* Your content here */}
          <h1 className="opacity-0 animate-fadeIn delay-0 md:w-4/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
            SARA
          </h1>
          <div className="opacity-0 animate-fadeIn delay-1 ml-12   uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-1xl  text-white leading-34 tracking-tight text-left max-w-[700px]">
            A reliable and user friendly SOLAR ARRAY DRIVE ASSEMBLY for small
            satellites
          </div>

          <div className=" flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-48 lg:py-64 xl:py-96 font-bold " data-aos="fade-zoom-in">
            <Link to="/products/sara/#main-features">

            <button
              className="opacity-0 animate-fadeIn delay-2 w-auto h-10 text-center text-white  px-4 transform transition-transform duration-200 hover:scale-110"
              style={{
                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
              }}
            >
              MAIN FEATURES
            </button>
            </Link>

            <Link to="/products/sara/#tech-specs">
            <button
              className="opacity-0 animate-fadeIn delay-2 w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
              style={{
                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
              }}
            >
              TECHNICAL SPECS
            </button>
            </Link>
          </div>
        </div>

        {/*ANIMATED BLOCKS*/}
        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={0.1} end={2} unit="X" />
            <Counter type="letter" start="" end="SAFE" />
            <Counter type="watt" start={100} end={500} unit="W" />
          </div>
        </div>
        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/products/SARA_REVOLVSPACE_00_ISOMETRICA 1.svg"
              className="w-auto md:w-5/12  h-auto "
                 alt="sara"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-12 font-bold "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              {/*<Link to="">*/}
              <button
                className=" w-auto h-10 text-center text-white  px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
                onClick={() => handleRequestClick()}
              >
                REQUEST STEP FILE
              </button>
              {/* </Link>*/}
              {/*<Link to="">*/}
              <button
                className=" w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
                onClick={() => handleRequestClick()}
              >
                REQUEST DATASHEET
              </button>
              {/*</Link>*/}
            </div>
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"

               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="modular"
                description="the affordable power transfer technology (no slip rings) and highly modular design ensures fast customization to different platform / solar arrays, 3-month lead times and high-volume production"          />
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="FAIL-SAFE"
                description="In case a failure or safe mode occurs, SnapBack, SARA's fail-safe device, returns the solar wings to a reference orientation, pre-selected on ground"
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Autonomous"
                description="No need to drive SARA from the OBC: SARA drives itself, thanks to its embedded sun sensors and advanced drive electronics"
            />
          </div>

        </div>

        {/* COLLAPSIBLE Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 my-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            customize your sara{" "}
            </h1>
          <div className="flex items-center justify-center "
               data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <img
              src="/SARA 2.0_ESPLOSO.png"
              className="w-auto md:w-3/12 h-auto"
             alt="sara"/>
          </div>
          <div>
            <div
              className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1"
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className="flex items-start justify-start ">
                <Collapsible
                  title="CORE MODULE"
                  additionalText={[
                    { description: "drive mechanism (SADM)" },
                    { description: "drive electronics (SADE)" },

                  ]}

                />
              </div>
              <div className="flex items-start justify-start">
                <Collapsible
                  title="ADD-ONS"
                  additionalText={[
                    { description: "sun sensors" },
                    { description: "high-reliability sun tracking algorithm" },
                    { description: "SnapBack" },
                    { description: "deployment hinges" },
                    { description: "solar array yoke" },

                  ]}

                />
              </div>
              <div className="flex items-start justify-start">
                <Collapsible
                  title="CUSTOMIZATION OPTIONS"
                  additionalText={[
                    { description: "multiple mounting and deployment configurations available" },
                    { description: "custom connectors" },
                    { description: "dedicated testing" },

                  ]}

                />
              </div>
            </div>
          </div>
        </div>

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            AVAILABLE VERSIONS{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="p-4">
                {/*bg-green-500*/}
                <div className="value-item "
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/products/products_1wing.svg"
                    title="SARA-MONO"
                    description={"1 wing per SARA unit\nEach wing controlled independently"}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" p-4">
                {/*bg-green-500*/}
                <div className="value-item "
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/products/products_2wings.svg"
                    title="SARA-DUO"
                    description={"2 wings per SARA unit\nBoth wings controlled together"}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  imageSrc="/products/products_geo.svg"
                  title="SARA-GEO"
                  description={"equipped with Radiation-HARDENED\n" +
                      "drive electronics (coming soon...)"}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>
        {/* TECHNICAL SPECIFICATIONS Section */}
        <div  className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40">
          {/* Your content here */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            TECHNICAL SPECIFICATIONS{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-4  gap-4 px-4 sm:grid-cols-1 " >

             <div
                 data-aos="fade-zoom-in"
                 data-aos-offset="300px"
                 data-aos-delay="400"
                 data-aos-easing="ease-in-sine"
                 data-aos-duration="600">
               <TechSpecs
                   title="MECHANICAL SPECS"
                   description={[
                     { title: "Size: ", description: "97x97x23mm" },
                     { title: "Mass: ", description: "0.3kg" },
                     { title: "Rotation range: ", description: " +/- 180°" },
                     {
                       title: "Nominal rotation speed: ",
                       description: " 10 deg/s",
                     },
                   ]}

               />
             </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="700"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="ELECTRICAL SPECS"
                    description={[
                      { title: "Power supply:  ", description: "12V (unregulated 10-18V)" },
                      { title: "power consumption:  ", description: "Idle: 0.2W\nHOLD: 2W\nACTUATION: 5W" },
                      { title: "power transfer type: ", description: "flex pcb (twist capsule)" },
                      {
                        title: "Maximum power transfer: ",
                        description: "500W @42V",
                      },
                      {
                        title: "Communication interface:  ",
                        description: "RS485 / CAN",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1000"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="THERMAL SPECS"
                    description={[
                      { title: "Operating  temperature range: ", description: "-40/+60 °C" },
                      { title: "SURVIVAL temperature range: ", description: "-50/+70 °C" },

                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="MODES OF OPERATIONS"
                    description={[
                      { title: "Autonomous  ", description: "sun tracking based on sara’s sun sensor data" },
                      { title: "Freeze ", description: "pauses autonomous actuation for a given time" },
                      { title: "Slave  ", description: " target angle set by the on-board computer" },
                      {
                        title: "Safe ",
                        description: " panels are (mechanically) returned to the reference angle by snapback",
                      },
                    ]}

                />
              </div>
            </div>
          </div>
        </div>

        {/* QUALIFICATION Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40" >
          {/* Your content here */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            QUALIFICATION{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-4 gap-4 px-4 sm:grid-cols-1 gap-y-20 align-top " data-aos="zoom-out-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600">
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="400"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="mechanical"
                  imageSrc="/products/product_q_mechanical.svg"
                  description="static loads, random, sine, and shock testing according to NASA GEVS"
                  textAlign="left"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="800"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="thermal"
                  imageSrc="/products/product_q_thermal.svg"
                  description="8 cycles between -50/+70 °C, with deployment and actuation in both hot and cold case"
                  textAlign="left"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="1200"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="radiation"
                  imageSrc="/products/product_q_radiation.svg"
                  description={"SEE test: 20, 50, 100 MeV\nTID test: up to 10 krad (50 krad test coming soon)"}
                  textAlign="left"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="1600"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="lifetime"
                  imageSrc="/products/product_q_lifetime.svg"
                  description="5 YEARS ACCELERATED LIFETIME TEST IN THERMAL VACUUM (58,000 full revolutions)"
                  textAlign="left"
                />
              </div>
            </div>
          </div>
        </div>

        {/* VIDEO Section */}
        <div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">
          {/* <h1 className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                        QUALIFICATION{" "}
                    </h1> */}

          <div>
            <div>
              {/* GIF sopra l'immagine di sfondo */}
              <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
              >
                <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 my-40">
          {/*  */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-40  text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            PRODUCT GALLERY{" "}
          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <ProductSlider />
          </div>
        </div>

        <Link to="/contact">
          <div className="flex items-center justify-center">
            <button
              className=" w-auto h-10 text-center text-white  font-bold py-2 px-4 transform transition-transform duration-200 hover:scale-110"
              style={{
                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
              }}
            >
              CONTACT US
            </button>
          </div>
        </Link>
      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}
    </div>
  );
}
