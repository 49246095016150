import React from 'react';
import { Link } from 'react-router-dom';

interface GradientButtonProps {
    to1: string;
    to2: string;
    description1: string;
    description2: string;
    aosOffset?: number;
    aosDelay?: number;
    aosDuration?: number;
    aosEasing?: string;
}

const GradientButtons: React.FC<GradientButtonProps> = ({ to1, to2, description1, description2, aosOffset, aosDelay, aosDuration, aosEasing }) => {
    return (
        <div
            className=" opacity-0 animate-fadeIn delay-2 flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-12 font-bold "

        >
        <Link to={to1}>
            <button
                className="w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                    background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
            >
                {description1}
            </button>
        </Link>
            <Link to={to2}>
            <button
                className="w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                    background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
            >
                {description2}
            </button>
        </Link>

        </div>
    );
};

export default GradientButtons;