import React from "react";

interface Date{
  mese:string;
  anno:string;
}
interface TeamProps {
  date?: Date;
  color?: string;
  title?: string;
  subtitle?: string;
}

class JourneyCircle extends React.Component<TeamProps> {
    render() {
      const { date, color, title, subtitle } = this.props;
  
      return (
        <div className="flex flex-col items-center justify-center text-white w-full py-8">
          <div className="relative w-24 h-24 md:w-48 md:h-48 lg:w-52 lg:h-52">
            <div
              className="rounded-full w-full h-full object-cover flex items-center justify-center"
              style={{
                border: `2px solid ${color}`,
              }}
            >
             <div
              className="rounded-full w-full h-full object-cover flex items-center justify-center flex-col"
              style={{
                border: `2px solid ${color}`,
              }}
            >
            
            <span className="uppercase font-bold" style={{
              fontFamily: 'Montserrat',
              fontSize: '1.5vw',
              fontWeight: 500,
              textAlign: 'center'
            }} >{date?.mese}</span>
            <span className="uppercase font-bold" style={{
              fontFamily: 'Montserrat',
              fontSize: '1.5vw',
              fontWeight: 500,
              textAlign: 'center'
            }} >{date?.anno}</span>
            </div>
            </div>
          </div>
          <div className="flex-col text-center mt-4 ">

          <p className=" font-bold">{title}</p>
          <p className="font-bold ">{subtitle}</p>
          </div>
        </div>
      );
    }
  }

export default JourneyCircle;