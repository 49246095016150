import Slider from "react-slick";
import JourneyCircle from "./JourneyCircle";

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  swipeToSlide: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const CircleSlider = () => (
  <Slider {...settings}>
    <JourneyCircle
      color="#00858F"
      date={{ mese: "September", anno: "2022" }}
      title="Company"
      subtitle="foundation"
    />
    
   <JourneyCircle
      color="#383456"
      date={{mese: "November", anno: "2022" }}
      title="First product"
      subtitle="at TRL 5"
    />
    <JourneyCircle
      color="#3E2950"
      date={{ mese: "January", anno: "2023" }}
      title="Pre-seed"
      subtitle="investment"
    />
    <JourneyCircle
      color="#353572"
      date={{ mese: "August", anno: "2023" }}
      title="First commercial"
      subtitle="customer"
    />
    <JourneyCircle
      color="#03466E"
      date={{ mese: "February", anno: "2024" }}
      title="Seed investment"
      subtitle=""
    />
    <JourneyCircle
      color="#03466E"
      date={{ mese: "April", anno: "2024" }}
      title="First space-qualified"
      subtitle="product"
    />
  </Slider>
);

export default CircleSlider;
