import React, {useEffect, useState} from "react";
import Configuratore_New from "../../view/Configuratore_New";
import HierarchyControls from "./HierarchyControls";
import {GLTF, GLTFLoader} from "three-stdlib";
import {Link} from "react-router-dom";
import U3ALL from './json/U3_ALL.json';
import U3_ALL from './json/U3_ALL.json';
import U5_ALL from './json/U5_ALL.json';
import U6_ALL from './json/U6_ALL.json';
import U8_ALL from './json/U8_ALL.json';
import U10_ALL from './json/U10_ALL.json';
import U12_ALL from './json/U12_ALL.json';
import U16_ALL from './json/U16_ALL.json';
import LeadForm from "../../components/LeadForm";

const modelData = { U3_ALL, U5_ALL, U6_ALL, U8_ALL, U10_ALL, U12_ALL, U16_ALL };


interface SelectedOptionDisplayProps {
    toVisualize: string[];
}

interface AdditionalText {
    description: string;
}

enum Mode {
    ADD = "ADD",
    RESET = "RESET",
}

type SelectedOptionsType = {
    [key: string]: number;
};
type Option = { label: string; value: string | null; name: string }; // Adjust according to your actual option structure
type Step = {
    number: number;
    name: string;
    question: string;
    subtitle?: string;
    additionalText?: AdditionalText[];
    // options can be an array of Option or a function that returns an array of Option
    options: Option[] | ((selectedOptions: Record<string, any>) => Option[]);
    color: string;
};

interface NodeHierarchy {
    name: string;
    children: { [key: string]: NodeHierarchy };
}

interface Model {
    name: string;
    index: number;
}

export default function ConfiguratoreUI() {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsType>(
        {}
    );
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
        null
    ); // Aggiunto per tenere traccia dell'indice dell'opzione selezionata

    const [error, setError] = useState(false);
    const [result, setResult] = useState<string[]>([]); // Specifica che result è un array di stringhe
    const [modelPath, setModelPath] = useState<string | null>(null);
    const [listPrev, setListPrev] = useState<string[]>([]);

    const [hierarchy, setHierarchy] = useState<NodeHierarchy | null>(null);
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
    const [baseName, setBaseName] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [activeModels, setActiveModels] = useState<Model[]>([]);

    //comparazione dei dati con excel
    const [totMinMass, setTotMinMass] = useState(0);
    const [totMaxMass, setTotMaxMass] = useState(0);
    const [totMinPower, setTotMinPower] = useState(0);
    const [totMaxPower, setTotMaxPower] = useState(0);
    const [PWRmultiplier, setPWRmultiplier] = useState(1);
    const [configBase, setConfigBase] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [interestedQuantity, setInterestedQuantity] = useState('');

    function GetCurrentActiveModel(): Model[] {

        const visibleModels: Model[] = [];
        const traverse = (node: NodeHierarchy) => {
            if (visibility[node.name]) {
                visibleModels.push({ name: node.name, index: currentStep });
            }
            if (node.children) {
                Object.values(node.children).forEach(traverse);
            }
        };
        if (hierarchy) {
            traverse(hierarchy);
        }
        return visibleModels;
    }

    const getInterestedQuantity = () => {
        return interestedQuantity;
    };

    const generateModel = () => {

        setOpenPopup(true);

        let tempMinMass = 0;
        let tempMaxMass = 0;
        let tempMinPower = 0;
        let tempMaxPower = 0;
        let tempPWRMultiplier = 1;
        console.log("temp multiplier initialized: "+tempPWRMultiplier);
        let typeCounter: { [key: string]: number } = {};
        let nodeNamesSet = new Set<string>();

        const currentActiveModels = GetCurrentActiveModel(); // Usa la nuova funzione qui
        const selectedQty = getInterestedQuantity();
        // All'interno della funzione generateModel
        console.log(`interested qty: ${selectedQty}`);



        for (const model of currentActiveModels) {
            const modelName = model.name;

            for (const modelsFile of Object.values(modelData)) {
                const modelsArray = Object.values(modelsFile)[0]; // Prendi il primo (e unico) array nel file

                const matchedModel = (modelsArray as any[]).find((m: any) => m.name === modelName);

                if (matchedModel) {
                    // Controlla se il nome del nodo è già stato aggiunto
                    if (nodeNamesSet.has(matchedModel.name)) {
                        console.error(`Duplicate node name found: ${matchedModel.name}`);
                        break; // Esci dalla funzione se viene trovato un duplicato
                    }

                    nodeNamesSet.add(matchedModel.name);

                    tempMinMass += matchedModel.minMass;
                    tempMaxMass += matchedModel.maxMass;
                    tempMinPower += matchedModel.minPower;
                    tempMaxPower += matchedModel.maxPower;
                    tempPWRMultiplier =tempPWRMultiplier * matchedModel.PWRmultiplier;
                    console.log("model"+ matchedModel.name +" multiplier: "+ matchedModel.PWRmultiplier);
                    console.log("temp multiplier multiplying: "+tempPWRMultiplier);


                    if (matchedModel.type) {
                        if (!typeCounter[matchedModel.type]) {
                            typeCounter[matchedModel.type] = 1;
                        } else {
                            typeCounter[matchedModel.type]++;
                        }
                    }
                }
            }
        }

        let configBaseString = '';
        for (const [type, count] of Object.entries(typeCounter)) {
            configBaseString += `${count} ${type}, `;
        }
        configBaseString = configBaseString.slice(0, -2);

        // Definizione dei nomi delle basi
        const baseNames: { [key: string]: string } = {
            '3': 'base 3U',
            '5': 'base micro piccolo',
            '6': 'base 6U',
            '8': 'base 8U',
            '10': 'base micro grande',
            '12': 'base 12U',
            '16': 'base 16U'
        };

        // Aggiungi il nome della base come primo elemento
        const baseLabel = baseNames[baseName] || 'base sconosciuta';
        configBaseString = `${baseLabel}, ${configBaseString}`;

        setTotMinMass(tempMinMass);
        setTotMaxMass(tempMaxMass);
        setTotMinPower(tempMinPower);
        setTotMaxPower(tempMaxPower);
        setPWRmultiplier(tempPWRMultiplier);
        setConfigBase(configBaseString);

        console.log(`totMinMass: ${tempMinMass}`);
        console.log(`totMaxMass: ${tempMaxMass}`);
        console.log(`totMinPower: ${tempMinPower}`);
        console.log(`totMaxPower: ${tempMaxPower}`);
        console.log(`PWRMultiplier : ${tempPWRMultiplier}`);
        console.log(`configBase: ${configBaseString}`);
        console.log(`interested qty: ${selectedQty}`);
    };

    const getWingsOptions = (): Option[] => {
        if (result[1] === "FIXED" && result[2] === "TOP") {
            return [
                {label: "1", value: "1", name: "1"},
                {label: "2", value: "2", name: "2"},
                {label: "4", value: "4", name: "4"},
            ];
        } else {
            return [
                {label: "1", value: "1", name: "1"},
                {label: "2", value: "2", name: "2"},
            ];
        }
    };

    const steps: Step[] = [
        {
            number: 1,
            name: "SATELLITE SIZE",
            question: "SELECT YOUR SATELLITE SIZE",

            options: [
                {label: "3u CUBESAT", value: "3", name: "3"},
                {label: "6u CUBESAT", value: "6", name: "6"},
                {label: "8u CUBESAT", value: "8", name: "8"},
                {label: "12u CUBESAT", value: "12", name: "12"},
                {label: "16u CUBESAT", value: "16", name: "16"},
                {label: "SMALL ESPA SATELLITE", value: "5", name: "5"},
                {label: "LARGE ESPA SATELLITE", value: "10", name: "10"},
            ],
            color: "#00858F",
        },
        {
            number: 2,
            name: "SADA",
            question: "DO YOU WANT A POWER BOOST?",
            subtitle: 'Some of the Advantages of using a solar array drive assembly:',
            additionalText: [
                {description: "increase your duty cycle"},
                {description: "combine payload operations with bus housekeeping"},
                {description: "achieve orbit-agnosticity and a truly standard platform"},
                {description: "reduce solar cell costs"},
                {description: "beat your competitor with a high-efficiency and high-performance platform'"},

            ],

            options: [
                {
                    label: "YES, I WANT ARTICULATED SOLAR ARRAYS",
                    value: "SARA",
                    name: "SARA",
                },
                {label: "NO, ONLY FIXED DEPLOYABLES", value: null, name: "FIXED"},
            ],
            color: "#4381AC",
        },
        {
            number: 3,
            name: "HINGE",
            question: "SELECT THE ROOT hinge position",

            options: [
                {label: "upper edge", value: "TOP_1_1", name: "TOP"},
                {label: "side edge", value: "SIDE_1_1", name: "SIDE"},
            ],
            color: "#676DB2",
        },
        {
            number: 4,
            name: "WINGS",
            question: "SELECT THE NUMBER OF WINGS",

            options: getWingsOptions(),
            color: "#A24BA9",
        },
        {
            number: 5,
            name: "DEPLOYABLES",
            question: "select the number of panels for each wing",

            options: [
                {label: "1", value: "1", name: "1"},
                {label: "2", value: "2", name: "2"},
                {label: "3", value: "3", name: "3"},
                {label: "4", value: "4", name: "FIXED_TOP1"},
            ],
            color: "#C12A95",
        },
        /*{
            number: 6,
            name: "ORDER SIZE",
            question: "how many satellites are you planning to build?",

            options: [
                {label: "1", value: "1", name: "1"},
                {label: "2-5", value: "2-5", name: "2-5"},
                {label: "6-10", value: "6-10", name: "6-10"},
                {label: "11-50", value: "11-50", name: "11-50"},
                {label: "50-100", value: "50-100", name: "50-100"},
                {label: ">100", value: ">100", name: "<100"},
            ],
            color: "#D80980",
        },*/
    ];

    useEffect(() => {
        loading ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto"

    }, [loading])
    useEffect(() => {
        const handleLoad = () => {
            // Forza lo scroll all'inizio della pagina
            window.scrollTo(0, 0);

            // Opzionalmente, resetta l'URL senza ricaricare la pagina per rimuovere l'anchor
            if (window.location.hash) {
                // Rimuove l'hash senza cambiare lo scroll
                window.history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        };

        // Aggiunge l'event listener quando il componente viene montato
        window.addEventListener('load', handleLoad);

        // Rimuove l'event listener quando il componente viene smontato
        return () => window.removeEventListener('load', handleLoad);
    }, []);


    useEffect(() => {

        const url = window.location.toString();
        if (url.includes('#')) {
            window.history.pushState({}, '', url.split('#')[0]);
        }

        setLoading(true)

        let firstOptions: Option[];
        const options = steps[0].options;
        if (typeof options === 'function') {
            firstOptions = options({});
        } else {
            firstOptions = options;
        }

        const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        const handleOptionsAsync = async () => {
            for (let index = firstOptions.length - 1; index >= 0; index--) {
                await wait(2000); // Aspetta 1 secondi
                handleOptionClick(index);
                if (index === 0) setLoading(false)
            }
        };

        handleOptionsAsync();
    }, []);


    useEffect(() => {
        if (currentStep > 0 && result.length === currentStep) {
            setSelectedOptionIndex(0); // Imposta di default la selezione sul primo elemento dello step corrente
            handleOptionClick(0); // Chiama la funzione per l'opzione del nuovo step corrente
            // Aggiorna le opzioni selezionate con l'opzione di default per lo step corrente
            setSelectedOptions((prevOptions) => ({
                ...prevOptions,
                [steps[currentStep].name]: 0,
            }));
        }

    }, [currentStep]);

    function resetApp() {
        setCurrentStep(0);
        setSelectedOptions({});
        setSelectedOptionIndex(null);
        setError(false);
        setResult([]);
        setModelPath(null);
        setListPrev([]);
        setHierarchy(null);
        setVisibility({});
        setBaseName("");
        setLoading(false);
        setActiveModels([]);
        setTotMinMass(0);
        setTotMaxMass(0);
        setTotMinPower(0);
        setTotMaxPower(0);
        setPWRmultiplier(1);
        setConfigBase('');
        setOpenPopup(false);
        setInterestedQuantity('');
    }

    function handleReset() {
        prevStep();

        activeModels
            .filter((model) => model.index > 0)
            .forEach((model) => {
                setVisibility2(false, undefined, undefined, model.name);
            });
        setVisibility2(false, undefined, Mode.RESET);
        // setSelectedOptions({}); // Resetta selectedOptions a un oggetto vuoto


        setResult(["PU" + baseName])

    }

    const nextStep = () => {
        if(currentStep === steps.length -1){
            generateModel();
        }
        if (currentStep < steps.length - 1) {
            // Aggiorna il passo corrente
            setCurrentStep(currentStep + 1);

            // Imposta di default la selezione sul primo elemento dello step successivo
            // setSelectedOptionIndex(0); // Assicurati che questa riga sia aggiunta
            // Aggiorna le opzioni selezionate con l'opzione di default per lo step successivo
            // setSelectedOptions((prevOptions) => ({
            //   ...prevOptions,
            //   [steps[currentStep + 1].name]: 0, // Imposta l'indice 0 per lo step successivo
            // }));
            const succStepIndex = currentStep + 1;
            setCurrentStep(succStepIndex);

            // Imposta selectedOptionIndex all'indice dell'opzione precedentemente selezionata per lo step corrente
            const succStepName = steps[succStepIndex].name;
            const succSelectedOptionIndex = selectedOptions[succStepName];
            setSelectedOptionIndex(succSelectedOptionIndex);
        }


    };
    // Array di frasi da mostrare
    const frasi = [
        "hold on...",
        "we are powering you up…",
        /*"Stiamo assemblando le tue ali",
        "Stiamo collegando i tuoi pannelli",
        "Stiamo stringendo i bulloni",
        "Stiamo usando la nostra miglior colla",
        "Stiamo aggiungendo i dettagli",
        "Siamo quasi pronti per il lancio",
        "Porta gli occhi al cielo",
        "Quasi fatto...",*/
        // Aggiungi altre frasi qui se necessario
    ];

    const toggleVisibility = (name: string) => {
        setVisibility((prev) => ({...prev, [name]: !prev[name]}));
    };
    const buildHierarchy = (node: any): NodeHierarchy => {
        let children: { [key: string]: NodeHierarchy } = {};
        if (node.children) {
            node.children.forEach((child: any) => {
                children[child.name] = buildHierarchy(child);
            });
        }
        return {
            name: node.name,
            children: children,
        };
    };
    const [indiceFrase, setIndiceFrase] = useState(0);

    const setVisibility2 = (
        value: boolean,
        list?: string[],
        mode?: Mode,
        name?: string
    ) => {
        if (list !== undefined && list.length > 0) {
            setListPrev(list); // Sovrascrivi con la nuova lista
            console.log("LISTPREV", [...listPrev, ...list]);
        }
        if (mode === Mode.RESET) {
            console.log("MODE RESET E LISTA ", listPrev);
            listPrev.forEach((name) => {
                setVisibility((prev) => {
                    if (prev[name] !== false) {
                        return {...prev, [name]: false};
                    }
                    return prev;
                });
            });
        }

        list?.forEach((name) => {
            if (value == true) {
                const model: Model = {name: name, index: currentStep};
                setActiveModels((prev) => [...prev, model]);
            } else {
                setActiveModels((prev) => prev.filter((model) => model.name !== name));
            }
            setVisibility((prev) => {
                if (prev[name] !== value) {
                    return {...prev, [name]: value};
                }
                return prev;
            });
        });
        if (name) {
            console.log("SETTO VISIBILITA", name, value);
            if (value == true) {
                const model: Model = {name: name, index: currentStep};
                setActiveModels((prev) => [...prev, model]);
            } else {
                setActiveModels((prev) => prev.filter((model) => model.name !== name));
            }
            setVisibility((prev) => {
                if (prev[name] !== value) {
                    return {...prev, [name]: value};
                }
                return prev;
            });
        }
    };
    useEffect(() => {
        // Cambia la frase ogni due secondi
        const intervallo = setInterval(() => {
            setIndiceFrase(indiceFrase => (indiceFrase + 1) % frasi.length);
        }, 2800);

        // Pulizia dell'intervallo quando il componente viene smontato
        return () => clearInterval(intervallo);
    }, [frasi.length]);

   /* useEffect(() => {
        console.log("ACTIVE MODELS", activeModels);
        // activeModels.forEach((model) => {
        //   console.log("ACTIVE MODELS", model.name);
        // });
    }, [activeModels]);*/

    const prevStep = () => {
        if (currentStep > 0) {
            const prevStepIndex = currentStep - 1;
            setCurrentStep(prevStepIndex);
            console.log("PREVSTEPINDEX", prevStepIndex);

            // Imposta selectedOptionIndex all'indice dell'opzione precedentemente selezionata per lo step corrente
            const prevStepName = steps[prevStepIndex].name;
            const prevSelectedOptionIndex = selectedOptions[prevStepName];
            setSelectedOptionIndex(prevSelectedOptionIndex);
        }
    };
    const SelectedOptionDisplay: React.FC<SelectedOptionDisplayProps> = ({
                                                                             toVisualize,
                                                                         }) => {
        return (
            <div style={{border: "1px solid black", padding: "10px"}}>
                {Array.isArray(toVisualize) && toVisualize.length > 0
                    ? toVisualize.map((option, index) => <div key={index}>{option}</div>)
                    : "Nessuna opzione selezionata"}
            </div>
        );
    };

    const initializeVisibility = (gltf: GLTF, selectedOptionName: string) => {
        const hierarchy = buildHierarchy(gltf.scene);

        if (hierarchy) {
            /*const activateAllChildren = (node: NodeHierarchy) => {
              if (node.children) {
                Object.keys(node.children).forEach((childName) => {
                  const childNode = node.children[childName];
                  // Activate the child node here
                  setVisibility2(true, undefined, undefined, childNode.name);
                  // Recursively activate the children of the child node
                  activateAllChildren(childNode);
                });
              }
            };*/

            // Activate all children before performing other operations
            //activateAllChildren(hierarchy);

            const printParentNodeNames = (
                node: NodeHierarchy,
                parentName: string | null = null
            ) => {
                if (node.children && Object.keys(node.children).length > 0) {
                    // Node has children, check if it's "scene"
                    if (node.name !== "Scene") {
                        setVisibility2(false, undefined, undefined, node.name);
                    }
                    // Recursively call the function on each child
                    Object.values(node.children).forEach((child) =>
                        printParentNodeNames(child, node.name)
                    );
                } else if (parentName === "Scene") {
                    // Node has no children and its parent is "scene"
                    if (node.name !== "SU" + selectedOptionName) {
                        setVisibility2(false, undefined, undefined, node.name);
                    }
                }
            };

            // Call the function on the root of the hierarchy
            printParentNodeNames(hierarchy);
        } else {
            console.log("hierarchy null");
        }
    };

    const handleOptionClick = (index: number) => {
        setSelectedOptions({
            ...selectedOptions,
            [steps[currentStep].name]: index,
        });
        setSelectedOptionIndex(index); // Aggiorna l'indice dell'opzione selezionata
        const currentOptions = steps[currentStep].options;
        if (Array.isArray(currentOptions)) {
            // Verifica che currentOptions sia un array
            const selectedOptionValue = currentOptions[index]?.value;
            const selectedOptionName = currentOptions[index]?.name;

            switch (currentStep) {
                case 0:
                    if (selectedOptionValue) {
                        setResult(["PU" + selectedOptionValue]);
                        const modelPath = `/configuratore/models/satellitetest/U${selectedOptionName}_ALL.gltf`;
                        setBaseName(selectedOptionName);
                        fetch(modelPath, {method: "GET"})
                            .then((response) => {
                                if (response.ok) {
                                    console.log("RESPONSE OK");
                                    setError(false);
                                    setModelPath(modelPath);

                                    // Fetch the model and initialize visibility
                                    const loader = new GLTFLoader();
                                    loader.load(modelPath, (gltf) => {
                                        initializeVisibility(gltf, selectedOptionName); // Initialize visibility based on your logic
                                    });
                                } else {
                                    setError(true);
                                    console.log("Il percorso del modello non esiste:", modelPath);
                                }
                            })
                            .catch((error) => {
                                setError(true);
                                console.error(
                                    "Errore durante la verifica del percorso del modello:",
                                    error
                                );
                            });
                    }

                    break;

                case 1: //SARA NO SARA
                    activeModels
                        .filter((model) => model.index > 1)
                        .forEach((model) => {
                            setVisibility2(false, undefined, undefined, model.name);
                        });
                    setVisibility2(false, undefined, Mode.RESET);

                    if (result.length > 1) {
                        // SOSTITUISCI
                        setResult([...result.slice(0, currentStep), selectedOptionName]);
                        const list = [];
                        if (selectedOptionValue === null) {
                            setVisibility2(
                                false,
                                undefined,
                                undefined,
                                "U" + baseName + "_SARA_TOP"
                            );
                            setVisibility2(
                                false,
                                undefined,
                                undefined,
                                "U" + baseName + "_HINGE_TOP"
                            );
                        } else {
                            setVisibility2(
                                true,
                                undefined,
                                undefined,
                                "U" + baseName + "_SARA_TOP"
                            );
                            setVisibility2(
                                true,
                                undefined,
                                undefined,
                                "U" + baseName + "_HINGE_TOP"
                            );
                        }
                    } else {
                        setResult([...result, selectedOptionName]);
                        if (selectedOptionValue === null) {
                            setVisibility2(
                                false,
                                undefined,
                                undefined,
                                "U" + baseName + "_SARA_TOP"
                            );
                            setVisibility2(
                                false,
                                undefined,
                                undefined,
                                "U" + baseName + "_HINGE_TOP"
                            );
                        } else {
                            setVisibility2(
                                true,
                                undefined,
                                undefined,
                                "U" + baseName + "_SARA_TOP"
                            );
                            setVisibility2(
                                true,
                                undefined,
                                undefined,
                                "U" + baseName + "_HINGE_TOP"
                            );
                        }
                    }
                    break;

                case 2: //HINGE
                    const list = [];
                    setVisibility2(false, undefined, Mode.RESET);
                    activeModels
                        .filter((model) => model.index > 2)
                        .forEach((model) => {
                            setVisibility2(false, undefined, undefined, model.name);
                        });
                    if (result.length > 2) {
                        const temp = [...result.slice(0, currentStep), selectedOptionName];
                        setResult(temp);
                        const newResult = temp.join("_");
                        list.push(newResult + "_1_1");
                        if (newResult.includes("SARA")) {
                            list.push(
                                "U" + baseName + "_SARA_" + selectedOptionName,
                                "U" + baseName + "_HINGE_" + selectedOptionName
                            );

                            if (selectedOptionName === "SIDE") {
                                list.push("U" + baseName + "_YOKE_SARA_SIDE_1");
                            }
                        }
                        setVisibility2(true, list);
                    } else {
                        const temp = [...result, selectedOptionName];
                        setResult(temp);
                        const newResult = temp.join("_");
                        list.push(newResult + "_1_1");
                        if (newResult.includes("SARA")) {
                            list.push(
                                "U" + baseName + "_SARA_" + selectedOptionName,
                                "U" + baseName + "_HINGE_" + selectedOptionName
                            );
                            if (selectedOptionName === "SIDE") {
                                list.push("U" + baseName + "_JOKE_SARA_SIDE_1");
                            }
                        }
                        setVisibility2(true, list);
                    }
                    console.log("LIST", list);
                    break;

                case 3: //NUMERO DI ALI
                    activeModels
                        .filter((model) => model.index > 3)
                        .forEach((model) => {
                            setVisibility2(false, undefined, undefined, model.name);
                        });
                    if (result.length > 3) {
                        // SOSTITUISCI
                        const temp = [...result.slice(0, currentStep), selectedOptionName];

                        setResult(temp);
                        if (selectedOptionName === "1") {
                            setVisibility2(
                                true,
                                undefined,
                                undefined,
                                temp.slice(0, currentStep).join("_") + "_1_1"
                            );
                            setVisibility2(
                                false,
                                undefined,
                                undefined,
                                temp.slice(0, currentStep).join("_") + "_2_1"
                            );

                            setVisibility2(
                                false,
                                undefined,
                                undefined,
                                "PU" +
                                (baseName === "6" || baseName === "8"
                                    ? parseInt(baseName) / 2
                                    : baseName) +
                                "_FIXED_TOP1"
                            );
                            if (temp.join("_").includes("SARA_SIDE")) {
                                setVisibility2(
                                    false,
                                    undefined,
                                    undefined,
                                    "U" + baseName + "_YOKE_SARA_SIDE_2"
                                );
                                setVisibility2(
                                    true,
                                    undefined,
                                    undefined,
                                    "U" + baseName + "_YOKE_SARA_SIDE_1"
                                );
                            }
                            // console.log(
                            //   "UNLOAD DEL MODELLO PU" +
                            //     (baseName === "6" || baseName === "8"
                            //       ? parseInt(baseName) / 2
                            //       : baseName) +
                            //     "_FIXED_TOP1"
                            // );
                        } else {
                            if (selectedOptionName === "2") {
                                setVisibility2(
                                    false,
                                    undefined,
                                    undefined,
                                    "PU" +
                                    (baseName === "6" || baseName === "8"
                                        ? parseInt(baseName) / 2
                                        : baseName) +
                                    "_FIXED_TOP1"
                                );

                                for (let i = 1; i <= 2; i++) {
                                    const visibilityKey =
                                        temp.slice(0, currentStep).join("_") + "_" + i + "_1";

                                    setVisibility2(true, undefined, undefined, visibilityKey);
                                }
                                console.log("TEMP " + temp);

                                if (temp.join("_").includes("SARA_SIDE")) {
                                    setVisibility2(
                                        true,
                                        undefined,
                                        undefined,
                                        "U" + baseName + "_YOKE_SARA_SIDE_2"
                                    );
                                }
                            } else {
                                for (let i = 1; i <= 2; i++) {
                                    const visibilityKey =
                                        temp.slice(0, currentStep).join("_") + "_" + i + "_1";

                                    setVisibility2(true, undefined, undefined, visibilityKey);
                                }

                                setVisibility2(
                                    true,
                                    undefined,
                                    undefined,
                                    "PU" +
                                    (baseName === "6" || baseName === "8"
                                        ? parseInt(baseName) / 2
                                        : baseName) +
                                    "_FIXED_TOP1"
                                );
                            }
                        }
                    } else {
                        //non è ancora stato selezionato
                        const temp = [...result, selectedOptionName];
                        setResult(temp);
                        const newResult = temp.join("_");
                        setVisibility2(true, undefined, undefined, newResult + "_1");
                    }
                    break;

                case 4: //NUMERO DI PANNELLI
                    const wings: string[] = [];
                    activeModels
                        .filter((model) => model.index == 3)
                        .forEach((model) => {
                            if (!wings.includes(model.name) && !model.name.includes("YOKE")) {
                                wings.push(model.name);
                            }
                        });
                    activeModels
                        .filter((model) => model.index > 4)
                        .forEach((model) => {
                            setVisibility2(false, undefined, undefined, model.name);
                        });
                    if (result.length > 4) {
                        const temp = [...result.slice(0, currentStep), selectedOptionName];
                        setResult(temp);
                        wings.forEach((wing, index) => {
                            const basePart =
                                index !== 2
                                    ? wing.slice(0, wing.lastIndexOf("_") + 1)
                                    : wing.slice(0, wing.lastIndexOf("_") + 1) + "TOP";
                            if (selectedOptionValue)
                                for (let i = 0; i <= 4; i++) {
                                    console.log("I : ", i, "e", selectedOptionValue);
                                    if (i <= parseInt(selectedOptionValue)) {
                                        setVisibility2(
                                            true,
                                            undefined,
                                            undefined,
                                            `${basePart}${i}`
                                        );
                                    } else {
                                        console.log("QUI");
                                        setVisibility2(
                                            false,
                                            undefined,
                                            undefined,
                                            `${basePart}${i}`
                                        );
                                    }
                                }
                        });
                    } else {
                        //non è ancora stato selezionato
                        const temp = [...result, selectedOptionName];
                        setResult(temp);
                        wings.forEach((wing) => {
                            const basePart = wing.slice(0, wing.lastIndexOf("_") + 1);
                            if (selectedOptionValue)
                                for (let i = 1; i <= 4; i++) {
                                    if (i <= parseInt(selectedOptionValue)) {
                                        setVisibility2(
                                            true,
                                            undefined,
                                            undefined,
                                            `${basePart}${i}`
                                        );
                                    } else {
                                        console.log("QUO");

                                        setVisibility2(
                                            false,
                                            undefined,
                                            undefined,
                                            `${basePart}${i}`
                                        );
                                    }
                                }
                        });
                    }
                break;


            }
        }
    };

    const getCurrentOptions = () => {
        const currentStepInfo = steps[currentStep];
        if (typeof currentStepInfo.options === "function") {
            return currentStepInfo.options(selectedOptions);
        }
        return currentStepInfo.options;
    };
    const handleHierarchyChange = (newHierarchy: NodeHierarchy) => {
        setHierarchy(newHierarchy);
    };
    return (
        <div className="relative flex flex-col h-full  mt-20">
            <div className="h-screen flex flex-col z-40 ">
                <h1 className=" opacity-0 animate-fadeIn delay-0 md:w-5/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
                    CONFIGURE YOUR <br/>
                    POWER SYSTEM
                </h1>
                <div
                    className=" opacity-0 animate-fadeIn delay-1 ml-12 mr-10 uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-md  text-white leading-34 tracking-tight text-left max-w-[1000px]">
                    try our beta to find out how to close your power budget and fast-track the quotation process. {" "}
                </div>
                <div
                    className=" opacity-0 animate-fadeIn delay-2 flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-12 font-bold "

                >
                    {
                        loading ? (
                            <div className="flex flex-col items-center text-white text-md font-normal uppercase p-2">
                                <div className="loader"></div>
                                {/* Assicurati di avere lo stile per .loader che implementa l'animazione dello spinner */}
                                <h2 className="p-2">{frasi[indiceFrase]}</h2>
                            </div>
                        ) : (
                            <Link to="#modelAnchor">
                                <button
                                    className="w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
                                    style={{
                                        background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                                    }}
                                >
                                    START GENERATING
                                </button>
                            </Link>
                        )
                    }
                </div>
            </div>
            <div id="modelAnchor" className="h-screen w-screen flex ">
                <div
                    className="w-2/5 h-screen mx-0  p-5 overflow-y-scroll overflow-x-hidden"
                    //style={{backgroundColor: "#2B2B2B"}}
                    style={{backgroundColor: "black"}}
                >
                    {openPopup ? (<div><LeadForm configBase={configBase} maxMass={totMaxMass} minMass={totMinMass} maxPower={totMaxPower} minPower={totMinPower} PWRMultiplier={PWRmultiplier} qty={interestedQuantity}/>  <div className="mt-4">
                        <label className=" px-4 block text-white uppercase mb-2 font-bold">DO YOU need something different?</label>
                        <Link to="/contact">
                        <button
                            type="button"
                            className="w-full px-4 text-white  py-2 uppercase font-bold border-2 border-white transition-transform duration-200 transform hover:scale-105"
                        >
                           contact us
                        </button>
                        </Link>
                        {/*<label className=" px-4 block text-white uppercase font-bold mt-8 my-2">CONFIGURE SOMETHING ELSE</label> with-border*/}

                            <button
                                type="button"
                                className="w-full  border-white px-4 text-white my-4 py-2 uppercase font-bold border-2  transition-transform duration-200 transform hover:scale-105"
                                onClick={() => {window.location.reload();/*inserire qui funzione di reset totale che porta allo step 0*/}}
                            >
                               start again
                            </button>


                    </div></div>):(<div className="text-white">
                        <div className="flex justify-between mb-4">
                            {steps.map((step, index) => (
                                <div key={index} className=" flex-grow text-center m-1">
                                    <p
                                        className={
                                            currentStep === index
                                                ? "text-white text-sm mb-1"
                                                : "text-gray-400 text-sm mb-1"
                                        }
                                    >
                                        {step.name}
                                    </p>
                                    <div
                                        style={{

                                            borderRadius: "25px",
                                            height: "8px",
                                            opacity: currentStep === index ? 1 : 0.5,
                                            backgroundColor: step.color, // Illumina la linea se è lo step corrente
                                            transition: "background-color 0.3s ease", // Opzionale: aggiunge una transizione per l'illuminazione
                                        }}
                                    ></div>
                                </div>
                            ))}
                        </div>
                        <div className="py-10">
                            <h1 className="items-center justify-center flex w-full text-2xl uppercase font-bold">
                                {steps[currentStep].question}
                            </h1>
                            <p className="my-4 uppercase">
                                {steps[currentStep].subtitle}
                                <div className="mt-2">
                                    <ul className="list-disc pl-5">
                                        {steps[currentStep].additionalText?.map((text, index) => (
                                            <li key={index} className="my-0 text-left">
                                                <p className="uppercase">{text.description}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </p>

                            <div>
                                {getCurrentOptions().map((option, index) => (
                                    <div className="py-2">
                                        <button
                                            key={index}
                                            className={`w-full border-2 border-white hover:bg-gradient-to-r from-gradient-start to-gradient-end text-white font-bold uppercase py-2 px-4  ${
                                                selectedOptionIndex === index
                                                    ? "bg-gradient-to-r from-gradient-start to-gradient-end"
                                                    : ""
                                            }`} // Applica condizionalmente la classe per il bordo colorato
                                            onClick={() => handleOptionClick(index)}
                                        >
                                            {option.label}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className=" bottom-0 left-0 right-0 flex justify-between p-4">
                            {
                                currentStep === 1 ?
                                    <button className="border-2 border-white" onClick={
                                        handleReset}><p className="px-2">RESET</p></button>
                                    :
                                    <button
                                        onClick={prevStep}
                                        disabled={currentStep === 0}
                                        className="bg-gray-700 text-white p-2 rounded-full h-10 w-10 font-bold disabled:opacity-0"
                                        style={{background: "#E6007E"}}
                                    >
                                        &lt;
                                    </button>

                            }

                            {currentStep === steps.length - 1 ? (
                                <button
                                    onClick={() => generateModel()}
                                    className="bg-gray-700 text-white p-2 font-bold transform transition-transform duration-200 hover:scale-110"
                                    style={{background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",}}
                                >
                                    Generate Model
                                </button>
                            ) : (
                                <button
                                    onClick={nextStep}
                                    disabled={currentStep === steps.length - 1}
                                    className="bg-gray-700 text-white p-2 rounded-full h-10 w-10 font-bold"
                                    style={{background: "#E6007E"}}
                                >
                                    &gt;
                                </button>
                            )}
                        </div>
                    </div>)}
                </div>
                <div className="w-full bg-black h-screen text-black">
                    <div className=" h-full">
                        {error ? (
                            <SelectedOptionDisplay toVisualize={result}/>
                        ) : (
                            <div>
                                <Configuratore_New
                                    visibility={visibility}
                                    setVisibility={setVisibility}
                                    modelPath={modelPath}
                                    hierarchy={hierarchy}
                                    onHierarchyChange={handleHierarchyChange}
                                    loading={loading}
                                />

                                {
                                    /*
                    <HierarchyControls
                                      hierarchy={hierarchy}
                                      toggleVisibility={toggleVisibility}
                                      visibility={visibility}
                                    />

                                    */
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
