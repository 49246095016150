import React, { useEffect } from "react";
import ImageText from "../common/ImageText";
import "../global.css";
import {Link} from "react-router-dom";
import CareersSlider from "../common/CareersSlider";
import ImageTextCareer from "../common/ImageTextCareer";
import ProductSlider from "../common/ProductSlider";
import GradientButtons from "../common/GradientButtons";


export default function Career() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);
    // Positions data
    const positions = [
        {
            title: "SPACECRAFT MECHANICAL ENGINEER",
            description:
                "FULL-TIME",
            location: "Turin (IT)",
            link: "https://www.linkedin.com/jobs/view/3916188547/?refId=UEmjtjWm5WqGHWY4y45YvQ%3D%3D&trackingId=UEmjtjWm5WqGHWY4y45YvQ%3D%3D",
        },
        {
            title: "ELECTRONICS ENGINEER",
            description:
                "FULL-TIME",
            location: "Turin (IT)",
            link: "https://www.linkedin.com/jobs/view/3921511414/?refId=dzosmcoqDvWBnJacAwrlLg%3D%3D&trackingId=dzosmcoqDvWBnJacAwrlLg%3D%3D",
        },{
            title: "open application",
            description:
                "FULL-TIME",
            location: "Turin (IT) / Noordwijk (NL) / Hybrid",
            link: "https://forms.gle/2KgrW4CpeHawZ9Gq9",
        },
        {
            title: "MECHANICAL ENGINEER",
            description:
                "INTERNSHIP",
            location: "Turin (IT)",
            link: "https://forms.gle/p9HzAsPB4F5RtM8N8",
        },
        {
            title: "ELECTRONICS ENGINEER",
            description:
                "INTERNSHIP",
            location: "Turin (IT)",
            link: "https://forms.gle/tSdBEm8v6QJ5BfM18",
        },
        {
            title: "EMBEDDED SYSTEMS ENGINEER",
            description:
                "INTERNSHIP",
            location: "Turin (IT)",
            link: " https://forms.gle/mSkUwrPkaavocQsZ6",
        },
        {
            title: "MARKETING SPECIALIST",
            description:
                "INTERNSHIP",
            location: "Turin (IT) / Noordwijk (NL) / Hybrid",
            link: "https://forms.gle/w7UJmcf1C6em9pVb7",
        },
        {
            title: "BUSINESS DEVELOPMENT SPECIALIST",
            description:
                "INTERNSHIP",
            location: "Turin (IT) / Noordwijk (NL) / Hybrid",
            link: "https://forms.gle/vwFcirWfn22CuM197",
        },{
            title: "open application",
            description:
                "INTERNSHIP",
            location: "Turin (IT) / Noordwijk (NL) / Hybrid",
            link: "https://forms.gle/ijv9Etwy4DaV7aJu9",
        },
        // Add more positions as needed
    ];

    return (
        <div>
            

            <div className="relative flex flex-col h-full   mt-20">
                {/* BECOME A REVOLVER Section */}
                <div className="h-screen flex flex-col   z-40 ">
                    {/* Your content here */}
                    <h1 className="opacity-0 animate-fadeIn delay-0 md:w-3/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
                        Become a space revolver{" "}
                    </h1>
                    <div className="opacity-0 animate-fadeIn delay-1 ml-12   uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-1xl  text-white leading-34 tracking-tight text-left max-w-[700px]">
                        WANT TO GIVE SATELLITE SUPERPOWERS?
                        <br/>
                        SEND AN APPLICATION!
                    </div>

                    <GradientButtons to1="/careers/#values"  description1="WHAT WE VALUE" to2="/careers/#positions" description2="OPEN POSITIONS" />

                     </div>
                     <div id='values' className="h-10"></div>

                {/*WHAT WE VALUE*/}
                <div  className=" mb-24 h-full flex flex-col bg-black bg-opacity-50 z-0">
                    {/* Your content here */}
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="my-12 text-6xl leading-120 tracking-tighter text-center uppercase font-bold text-white z-20 ">
                        WHAT WE VALUE
                    </h1>
                    <div
                        className=" absolute inset-0 flex items-center justify-center z-0 mb-10 2xl:opacity-100 lg:opacity-100 md:opacity-100 sm:opacity-100 ">
                        <img
                            className="object-cover h-1/2 w-auto"
                            src="/render_erica/REVOLVE SPACE_WEB BACKGROUND_03_CAREER 1.jpg"
                            alt="Moon"
                        />
                    </div>

                    <div className="">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 px-4 sm:grid-cols-1 gap-y-20 items-start">

                        <div className="value-item "
                             data-aos="fade-zoom-in"
                             data-aos-offset="300px"
                             data-aos-delay="400"
                             data-aos-easing="ease-in-sine"
                             data-aos-duration="600">
                                <ImageTextCareer
                                    text="HONESTY"
                                    imageSrc="/careers_icons/careers_transparency.svg"
                                    description="We value directness, transparency, and concreteness. we appreciate a humble and “no-bs” attitude."
                                    textAlign="left"
                                />
                            </div>
                            <div className="value-item"
                                 data-aos="fade-zoom-in"
                                 data-aos-offset="300px"
                                 data-aos-delay="800"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-duration="600"
                            >
                                <ImageTextCareer
                                    text="ACCOUNTABILITY"
                                    imageSrc="/careers_icons/careers_responsiveness.svg"
                                    description="WE ARE NOT afraid of saying 'I messed up'. We want to identify obstacles as soon as possible, so that we can work around them fast. "
                                    textAlign="left"
                                />
                            </div>
                            <div className="value-item"
                                 data-aos="fade-zoom-in"
                                 data-aos-offset="300px"
                                 data-aos-delay="1200"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-duration="600"
                            >
                                <ImageTextCareer
                                    text="PROACTIVENESS"
                                    imageSrc="/careers_icons/careers_agility.svg"
                                    description="IN A GROWING COMPANY, EACH PERSON MAKES A DIFFERENCE. WE RELY ON EACH OTHER'S PROACTIVE APPROACH TO KEEP IMPROVING. "
                                    textAlign="left"
                                />
                            </div>
                            <div className="value-item"
                                 data-aos="fade-zoom-in"
                                 data-aos-offset="300px"
                                 data-aos-delay="1600"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-duration="600"
                            >
                                <ImageTextCareer
                                    text="OBJECTIVE-DRIVEN"
                                    imageSrc="/careers_icons/careers_reilability.svg"
                                    description="WE VALUE THE EFFECTS OF OUR WORK, NOT THE TIME SPENT SITTING AT THE DESK. WE REJECT MICROMANAGEMENT AND EMPOWER EVERY TEAM MEMBER FOR INDEPENDENCE."
                                    textAlign="left"
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {/* PHRASE Section */}
                <div className="h-full flex flex-col z-40">
                    {/* Your content here */}

                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="2000"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white mx-16 ">
                        At Revolv Space, we are dedicated to building a diverse, inclusive and authentic workplace. <br/>If this resonates with you, check out the open positions and join our team!{" "}
                    </h1>

                </div>
                <div id='positions' className="h-10"></div>

                {/* OPEN POSITIONS  Section */}
                <div className="h-full flex flex-col z-40 mx-4">
                    {/* Your content here */}
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        
                        className=" my-12 text-6xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
                        OPEN POSITIONS{" "}
                    </h1>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white ">
                        CLICK ON A POSITION TO APPLY!{" "}
                    </h1>
                    <div>
                        <div
                            data-aos="fade-zoom-in"
                            data-aos-offset="300px"
                            data-aos-delay="800"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 sm:mt-0">
                            {" "}
                            {/* Add top margin on mobile */}
                            {positions.map((position, index) => (
                                <a
                                    key={index}
                                    href={position.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className=""
                                >
                                    <div
                                        className=" with-border relative group   p-4 transition-all duration-200 overflow-hidden">
                                        <div
                                            className="absolute inset-0 transform -scale-x-3 -scale-y-3 group-hover:scale-100 transition-transform duration-200"></div>
                                        <div
                                            className="relative z-10 flex flex-col sm:flex-row justify-between items-left ">
                                            {" "}
                                            {/* Make it a column on mobile */}
                                            <div className="max-w-[calc(100%-1.5rem)]">
                                                {" "}
                                                {/* Adjust as needed */}
                                                <p className="text-sm text-white uppercase">
                                                    {position.description}
                                                </p>
                                                <h2 className="text-lg font-bold text-white uppercase">
                                                    {position.title}
                                                </h2>
                                                <p className="text-sm text-white uppercase">
                                                    {position.location}
                                                </p>
                                            </div>
                                            <div
                                                className="opacity-0 group-hover:opacity-100 transition-opacity duration-200
                                                 absolute right-0 bottom-0"
                                            >
                                                <img
                                                    className="h-6 w-6 fill-current text-blue-500"
                                                    src="/commons/arrow_forward.svg"
                                                    alt="arrow"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>



                {/* PRODUCT GALLERY Section */}
                <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 my-40">
                    {/*  */}
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" my-40  text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
                        PHOTO GALLERY
                    </h1>

                    <div
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600">
                        <CareersSlider />
                    </div>
                </div>

                <Link to="/contact">
                    <div className="flex items-center justify-center">
                        <button
                            className=" w-auto h-10 text-center text-white  font-bold py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                            style={{
                                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                            }}
                        >
                            CONTACT US
                        </button>
                    </div>
                </Link>

            </div>
        </div>
    );
}
