import React, { useEffect } from "react";
import "../global.css";
import News from "../common/News";
import SimpleSlider from "../common/Slider";
import SliderItem from "../common/SliderItemHome";
import ImageTextHome from "../common/ImageText_Home";
import {Link, useLocation} from "react-router-dom";
import ProductHome from "../common/ProductHome";
import SliderItemHome from "../common/SliderItemHome";
import GradientButtons from "../common/GradientButtons";

export default function Home() {

    const location = useLocation();
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
        window.onload = () => {
            window.scrollTo(0, 0);
        };
      }, []);


    return (
        <div>
            <div className="relative flex flex-col h-full  mt-20">
                {/* BECOME A REVOLVER Section */}
                <div className="h-full flex flex-col   z-40  ">
                    <h1 className=" opacity-0 animate-fadeIn delay-0 md:w-4/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
                        Supercharge your <br/>smallsat missions
                    </h1>
                    <div className=" opacity-0 animate-fadeIn delay-1 ml-12 mr-10 uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-md  text-white leading-34 tracking-tight text-left max-w-[700px]">
                        We redefine small satellite capabilities through <br/>reliable and
                        affordable space mechanisms and power systems
                    </div>

                    <GradientButtons to1="/home/#products" to2="/about" description1="OUR PRODUCTS" description2="WHY REVOLV" />

                </div>

                <div className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 z-40 pt-10">
                    <div>
                        <div className="grid  md:grid-cols-2 gap-4 sm:grid-cols-1 gap-y-20 ">
                            <div
                                className="value-item"
                                data-aos="zoom-out-up"
                                data-aos-offset="100"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="600"
                            >
                                <ImageTextHome
                                    text="SPACE MECHANISMS"
                                    imageSrc="SARA_01_ISOMETRICA.png"
                                    description="We develop custom mechanisms compatible with the size and budget of small satellite missions, without compromising on reliability"
                                    textAlign="left"
                                />
                            </div>
                            <div
                                className="value-item"
                                data-aos="zoom-out-up"
                                data-aos-offset="100"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="600"
                            >
                                <ImageTextHome
                                    text="SOLAR ARRAYS"
                                    imageSrc="PANELS_01_ISOMETRICA.png"
                                    description="We provide turnkey power generation systems, customized for your spacecraft and co-engineered with transparency together with your platform design team"
                                    textAlign="left"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*SLIDER*/}
                <div
                    className="relative mb-20 flex items-center justify-center z-30 w-full h-full"
                    data-aos="zoom-in-out"
                    data-aos-offset="100"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                >
                    <SimpleSlider>
                        {
                            <SliderItemHome
                                title="For satellite integrators"
                                description={"Gone are the days when employing mechanisms on small satellites was a daunting challenge. We're rewriting the rules.\n\nOur actuation systems boost the performance of your small satellites and the capabilities that you can offer to your customers."}
                            />
                        }
                        {
                            <SliderItemHome
                                title="For satellite Operators"
                                description={"Forget about low uptimes and satellite unavailability due to the platform limitations.\n\nOur high-performance devices elevate your satellite's potential, boosting the duty cycles of your platforms, your mission return and your revenues. "}
                            />
                        }
                    </SimpleSlider>
                </div>

                {/* PRODUCTS Section */}
                <div
                    id="products"
                    className="py-24 h-full flex flex-col bg-black bg-opacity-50 z-0 justify-center "
                >
                    <div className="relative flex items-center w-full">
                        <div className="grid  md:grid-cols-1  ">
                            <div className="col-span-3 flex justify-center">
                                <h1

                                    className="text-4xl font-bold text-white"
                                    data-aos="zoom-in-out"
                                    data-aos-offset="100"
                                    data-aos-easing="ease-in-sine"
                                    data-aos-duration="600"
                                >
                                    PRODUCTS
                                </h1>
                            </div>

                            <div className="flex flex-col md:flex-row justify-center items-start gap-8 mx-10">
                                <div
                                    className="value-item w-full"
                                    data-aos="zoom-out-up"
                                    data-aos-offset="100"
                                    data-aos-easing="ease-in-sine"
                                    data-aos-duration="300"
                                    data-aos-delay="100"
                                >
                                    <ProductHome
                                        subheading="THE PANEL"
                                        heading="PAVEL"
                                        subtitle="COMING SOON"
                                        interactable={false}
                                        imageSrc="\HOME_1.png"
                                    />
                                </div>

                                <div
                                    className="value-item w-full"
                                    data-aos="zoom-out-up"
                                    data-aos-offset="100"
                                    data-aos-easing="ease-in-sine"
                                    data-aos-duration="600"
                                    data-aos-delay="300"
                                >
                                    <ProductHome
                                        subheading="Solar Array Drive Assembly"
                                        heading="SARA"
                                        subtitle="TRL 7"
                                        interactable={true}
                                        imageSrc="\HOME_2.png"
                                    />
                                </div>
                                <div
                                    className="value-item w-full"
                                    data-aos="zoom-out-up"
                                    data-aos-offset="100"
                                    data-aos-easing="ease-in-sine"
                                    data-aos-duration="600"
                                    data-aos-delay="400"
                                >
                                    <ProductHome
                                        subheading="THE HINGE"
                                        heading="HOLLY"
                                        subtitle="COMING SOON"
                                        interactable={false}
                                        imageSrc="/00_vista-isometrica-(1).png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* TRUSTED BY */}
                <div className="flex flex-col  relative ">
                    <div className="flex items-center w-full h-screen">
                        <div className="grid grid-cols-2 gap-4 text-white w-full mx-5">
                            <div className="col-span-2 flex justify-center">
                                <h1
                                    className="text-4xl font-bold my-14"
                                    data-aos="fade-zoom-in"
                                    data-aos-offset="200"
                                    data-aos-easing="ease-in-sine"
                                    data-aos-duration="800"
                                    data-aos-once="true"
                                >
                                    TRUSTED BY
                                </h1>
                            </div>

                            <div
                                className=" col-span-2 md:col-span-1 my-6 xl:col-span-1   flex flex-col items-center "
                                data-aos="fade-zoom-in"
                                data-aos-offset="400"
                                data-aos-delay="300"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="600"
                                data-aos-once="true"
                            >
                                <div
                                    className="flex items-center justify-center w-full h-full transform transition-transform duration-200 hover:scale-110">
                                    <img
                                        className="object-cover "
                                        src="/deimos.svg"
                                        alt="deimos"
                                    />
                                </div>
                            </div>
                            {/*<div
                                className="col-span-3 md:col-span-1 my-6 xl:col-span-1 flex flex-col items-center"
                                data-aos="fade-zoom-in"
                                data-aos-offset="400"
                                data-aos-delay="600"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="600"
                                data-aos-once="true"
                            >
                                <div
                                    className="flex items-center justify-center w-full h-full transform transition-transform duration-200 hover:scale-110">
                                    <img
                                        className="object-cover "
                                        src="/c3s-logo-color.svg"
                                        alt="c3s"
                                    />
                                </div>
                            </div>*/}
                            <div
                                className="col-span-2 md:col-span-1 my-6 xl:col-span-1 flex flex-col items-center"
                                data-aos="fade-zoom-in"
                                data-aos-offset="400"
                                data-aos-delay="900"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="600"
                                data-aos-once="true"
                            >
                                <div
                                    className="flex items-center justify-center w-full h-full transform transition-transform duration-200 hover:scale-110">
                                    <img
                                        className="object-cover"
                                        src="/logo-u-space.svg"
                                        alt="u-space"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* NEWS Section */}
                    <div className="px-20 mt-10 pt-20 flex flex-col">
                        <h1
                            className="text-4xl font-bold text-white text-center py-10 mb-8 mt-10"
                            data-aos="fade-zoom-in"
                            data-aos-offset="200"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="800"
                            data-aos-once="true"
                        >
                            REVOLV IN THE NEWS
                        </h1>

                        <div
                            data-aos="fade-zoom-in"
                            data-aos-offset="100"
                            data-aos-delay="300"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            data-aos-once="true"
                        >
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to="https://commercialisation.esa.int/2023/06/revolv-space-develops-critical-components-to-enable-continuous-payload-operations/">
                                <button
                                    className="transform transition-transform duration-200 hover:scale-110"
                                >
                                    <News
                                        imageSrc="Rectangle_35.png"
                                        description="REVOLV SPACE DEVELOPS CRITICAL COMPONENTS TO ENABLE CONTINUOUS PAYLOAD OPERATIONS"
                                    />
                                </button>
                            </Link>

                        </div>

                        <div
                            data-aos="fade-zoom-in"
                            data-aos-offset="100"
                            data-aos-delay="400"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            data-aos-once="true"
                        >
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to="https://www.sbicnoordwijk.nl/revolv-space-components-for-small-satellites-continuous-payload-operations-esabic/">

                                <button className="transform transition-transform duration-200 hover:scale-110">
                                    <News
                                        imageSrc="Rectangle_36.png"
                                        description="Revolv Space rethinks critical components for small satellites to enable continuous payload operations"
                                    />
                                </button>
                            </Link>
                        </div>


                        <div
                            data-aos="fade-zoom-in"
                            data-aos-offset="100"
                            data-aos-delay="500"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            data-aos-once="true"


                        >
                            <Link
                                target="_blank"
                                  rel="noopener noreferrer"
                                  to="https://torino.corriere.it/notizie/economia/24_marzo_06/da-israele-agli-usa-noi-innovatori-all-estero-che-abbiamo-fatto-di-torino-la-nostra-silicon-valley-e7f0b4c3-2c15-43b9-b504-77df47ec1xlk.shtml">

                                <button className="transform transition-transform duration-200 hover:scale-110">
                                    <News
                                        imageSrc="Rectangle_32.png"
                                        description="«from israel to the us. we, the innovators abroad, making turin our silicon valley» (in italian)»"
                                    />
                                </button>
                            </Link>
                        </div>

                        <div
                            data-aos="fade-zoom-in"
                            data-aos-offset="100"
                            data-aos-delay="500"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            data-aos-once="true"
                        >
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to="https://drive.google.com/file/d/1sii5J6H9OkxLTHVyH4if914BCwo0eyS9/view?usp=sharing">

                                <button className="transform transition-transform duration-200 hover:scale-110">
                                    <News
                                        imageSrc="Rectangle_35.png"
                                        description="«Revolv Space Secures €2.6 Million to Boost Smallsat Performance»"
                                    />
                                </button>
                            </Link>
                        </div>

                    </div>
                    <div className="flex items-center justify-center">
                        <Link to="/contact">
                            <button
                                className=" w-auto h-10 text-center text-white  font-bold py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                                style={{
                                    background:
                                        "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                                }}
                            >
                                CONTACT US
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
