import React from 'react';
import { Link } from 'react-router-dom';

interface ProductHomeProps {
    subheading: string;
    heading: string;
    subtitle: string;
    interactable: boolean;
    imageSrc: string;
}

const ProductHome: React.FC<ProductHomeProps> = ({ subheading, heading, subtitle, interactable, imageSrc }) => {
    const content = (
        <>
            <div className={`relative flex flex-1 items-start justify-start w-full h-full ${interactable ? 'hover:scale-110 transition-transform duration-200' : ''}` } >
                <img className="object-cover w-full h-full" src={imageSrc} alt={heading} />
                {!interactable && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        {/* <p className="text-3xl font-medium text-white">COMING SOON</p>*/}
                    </div>
                )}
            </div>
            <div className="gradient-line w-full my-1 self-start"></div>
            <p className="text-4.5 leading-120 tracking-tighter text-left uppercase text-white">{subheading}</p>
            <p className="text-4xl leading-120 tracking-tighter text-left uppercase text-white">{heading}</p>
            <p className="text-4.5 leading-120 tracking-tighter text-left uppercase text-white my-2">{subtitle}</p>
        </>
    );

    return (
        <div className="flex-1 flex flex-col items-center md:items-start">
            {interactable ? (
                <Link to={`/products/${heading.toLowerCase()}`}>
                    {content}
                </Link>
            ) : (
                <div>{content}</div>
            )}
        </div>
    );
};

export default ProductHome;